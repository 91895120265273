import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import './Beyondbooks.css';
import sportimg from '../Assets/sport-img.png';
import cocurricular from '../Assets/co-curricular.png'
import eventimg from '../Assets/event-img.png'
import beyond1 from '../Assets/beyond1.svg'
import beyond2 from '../Assets/beyond2.svg'
import beyond3 from '../Assets/beyond3.svg'
import beyond4 from '../Assets/beyond4.svg'
import beyond5 from '../Assets/beyond5.svg'
const Beyondbooks = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    const settings = {
        dots: false,
        infinite: true,
          
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    arrows: true,
                    speed: 500, autoplay: true,
                    autoplaySpeed: 3000, 
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='position-relative announcement' id="Beyondbook">
            <img className='beyond1' src={beyond1} alt='design-img' />
            <img className='beyond2' src={beyond2} alt='design-img' />
            <img className='beyond3' src={beyond3} alt='design-img' />
            <img className='beyond4' src={beyond5} alt='design-img' />
            <img className='beyond5' src={beyond4} alt='design-img' />

            <div className='container boyndthebook'>
                <div className='position-relative mb-5'>
                    <h2  className='line'>Beyond the book</h2>
                </div>
                <h4  className='mb-4 fw-600' style={{ color: "#2D306F" }}>Dive into the diversity of our school experiences</h4>


                <Slider {...settings}>
                    <div className='text-center activity-img'>
                        <img src={eventimg} alt='activity-img' />
                        <Link  to={{ pathname: "/Events" }} className='activity'>Events</Link>
                    </div>
                    <div className='text-center activity-img'>
                        <img src={sportimg} alt='activity-img' />
                        <Link  to={{ pathname: "/Sports" }} className='activity'> Sports</Link>
                    </div>
                    <div className='text-center activity-img'>
                        <img src={cocurricular} alt='activity-img' />
                        <Link  to={{ pathname: "/CoCurricular" }} className='activity'>Co-curricular Activities</Link>
                    </div>
                </Slider>
                {/* <Row className='mb-5 activity-img'>
                        <Col md xs={6} className='text-center'>
                            <img src={ eventimg} alt='activity-img' />
                            <Link to={{ pathname: "/Events"}} className='activity'>Events</Link> 
                        </Col>
                        <Col md xs={6} className='text-center'>
                            <img src={sportimg} alt='activity-img' />
                            <Link to={{ pathname: "/Sports"}}  className='activity'> Sports</Link> 
                        </Col>
                        <Col md xs={6} className='text-center mob-20'>
                            <img src={cocurricular  } alt='activity-img' />
                            <Link to={{ pathname: "/CoCurricular"}}  className='activity'>Co-curricular </Link> 
                        </Col>
                    </Row> */}
            </div>
        </div>
    )
}

export default Beyondbooks
